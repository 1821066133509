import { parse } from "cookie";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { setSessionStorage } from "./session_store_helper";
import { getCookie, getServerSideCookie } from "./cookie_helper";
import { NextApiRequest, NextApiResponse } from "next";
import { NextRequest, NextResponse } from "next/server";

interface ExtendedJwtPayload extends JwtPayload {
  roles?: string[];
  phone_verified: boolean;
  email_verified: boolean;
  verified: boolean;
}

class JWTHelper {
  private static readonly ACCESS_TOKEN_NAME = "_access_token";
  private static readonly REFRESH_TOKEN_NAME = "_refresh_token";
  private static refreshTokenInProgress: boolean = false;
  private static refreshTokenPromise: Promise<string | null> | null = null;

  static isTokenValid(token: string): boolean {
    if (!token) {
      return false;
    }

    try {
      const decodedToken = this.decodeToken(token);
      if (!decodedToken) {
        return false;
      }

      const currentTime = Math.floor(Date.now() / 1000);
      return decodedToken.exp ? decodedToken.exp > currentTime : false;
    } catch (error) {
      console.error("Invalid token:", error);
      return false;
    }
  }

  static decodeToken(token: string): ExtendedJwtPayload | null {
    try {
      return jwtDecode<ExtendedJwtPayload>(token);
    } catch (error) {
      return null;
    }
  }

  static storeToken(token: string, expires: number = 1): void {
    document.cookie = `${this.ACCESS_TOKEN_NAME}=${token};`;
  }

  static retrieveToken(isRefreshToken: boolean = false): string | null {
    const name =
      (isRefreshToken ? this.REFRESH_TOKEN_NAME : this.ACCESS_TOKEN_NAME) + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  static removeToken(isRefreshToken: boolean = false): void {
    const tokenName = isRefreshToken
      ? this.REFRESH_TOKEN_NAME
      : this.ACCESS_TOKEN_NAME;
    document.cookie = `${tokenName}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;secure;SameSite=Strict`;
  }

  static getClaim(token: string, claim: string): any {
    const decodedToken = this.decodeToken(token) as { [key: string]: any };
    return decodedToken ? decodedToken[claim] : null;
  }

  static async fetchNewAccessToken(): Promise<string | null> {
    if (this.refreshTokenInProgress) {
      return this.refreshTokenPromise!;
    }

    this.refreshTokenInProgress = true;
    const refreshToken = this.retrieveToken(true);
    if (!refreshToken) {
      this.refreshTokenInProgress = false;
      this.logout();
      throw new Error("No refresh token found");
    }

    this.refreshTokenPromise = fetch(`/api/refresh-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh_token: refreshToken }),
    })
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          this.refreshTokenInProgress = false;
          this.refreshTokenPromise = null;
          return data["data"].access_token;
        } else {
          throw new Error("Failed to refresh token");
        }
      })
      .catch((error) => {
        console.error("Error refreshing token:", error);
        this.logout();
        this.refreshTokenInProgress = false;
        this.refreshTokenPromise = null;
        throw error;
      });

    return this.refreshTokenPromise;
  }

  static logout(): void {
    this.removeToken(false);
    this.removeToken(true);
    // Clear session storage or local storage if used
    sessionStorage.clear();
    localStorage.clear();

    // Clear client-side accessible cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c.trim().split("=")[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    });

    window.location.href = "/login";
  }
}

export default JWTHelper;

export function retrieveAndStoreToken(): void {
  const auth = getCookie("_decoded");
  if (auth) {
    const urlEncodedJson = auth;
    const decodedJsonString = decodeURIComponent(urlEncodedJson);
    const jsonObject = JSON.parse(decodedJsonString);
    setSessionStorage("user", jsonObject);
  } else {
    throw new Error("No auth token found in cookies");
  }
}

export const fetchAccessToken = (): string | null => JWTHelper.retrieveToken();

export const fetchRefreshToken = (): string | null =>
  JWTHelper.retrieveToken(true);

export async function makeApiRequest(
  url: string,
  options: RequestInit
): Promise<Response> {
  let accessToken = fetchAccessToken();

  if (!accessToken || !JWTHelper.isTokenValid(accessToken)) {
    accessToken = await JWTHelper.fetchNewAccessToken();
  }

  options.headers = {
    ...options.headers,
    Authorization: `Bearer ${accessToken}`,
  };

  const response = await fetch(url, options);

  if (response.status === 401) {
    const errorData = await response.json();
    if (errorData.code === "access_token_expired") {
      const newAccessToken = await JWTHelper.fetchNewAccessToken();
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${newAccessToken}`,
      };
      return fetch(url, options);
    }
  }

  return response;
}

export async function makeServerApiRequest(
  url: string,
  options: RequestInit,
  req: NextRequest
): Promise<Response> {
  let accessToken = getServerSideCookie(req, "_access_token");

  options.headers = {
    ...options.headers,
    Authorization: `Bearer ${accessToken}`,
  };

  const response = await fetch(url, options);

  if (response.status === 401) {
    const errorData = await response.json();
    if (errorData.code === "access_token_expired") {
      const newAccessToken = await JWTHelper.fetchNewAccessToken();
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${newAccessToken}`,
      };
      return fetch(url, options);
    }
  }

  console.log("respsss ", response);

  return response;
}
