"use client";

import Link from "next/link";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useRouter } from "next/navigation";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import React from "react";
import JWTHelper, {
  fetchAccessToken,
  retrieveAndStoreToken,
} from "@/utils/jwt_helper";

import { toast } from "@/components/ui/use-toast";
import { EyeNoneIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import Image from "next/image";

const formSchema = z.object({
  username: z.string().superRefine((val, ctx) => {
    const containsSpecialCharacters = /[!@#$%^&*(),.?":{}|<>]/.test(val);

    if (containsSpecialCharacters) {
      try {
        z.string().email().parse(val);
      } catch (error) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Invalid username format",
        });
        return z.NEVER;
      }
    }

    if (val === "") {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "username ID/Username is required",
      });
    }

    if (val.length < 2) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Username must be atleast 2 characters",
      });
    }
    return val;
  }),
  password: z.string({ required_error: "Password is required" }).min(8, {
    message: "Password must be at least 8 characters long",
  }),
});

export default function Login() {
  const router = useRouter();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const completeProfileHandler = () => {
    router.replace(`/complete-profile`);
  };

  React.useEffect(() => {
    const token = fetchAccessToken();

    if (token && JWTHelper.isTokenValid(token)) {
      router.replace("/dashboard");
    }
  }, [loading]);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setLoading(true);
    try {
      const res = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: values.username,
          password: values.password,
        }),
      });

      if (res.ok) {
        retrieveAndStoreToken();

        completeProfileHandler();
      } else {
        const data = await res.json();
        toast({
          variant: "destructive",
          title: `${data.message}`,
          description: "",
          action: <></>,
        });
      }

      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex flex-row w-full h-screen z-10 bg-white">
      <div className="hidden lg:flex flex-col w-1/2 h-full bg-white justify-center items-center">
        <Image
          src="/2853458.png"
          alt="Register"
          width={500}
          height={500}
          className="mx-auto"
        />
        <span className="text-4xl font-bold text-gray-800">Gritup360</span>
        <span className="text-lg text-gray-800">
          A curated platform for physiotherapists
        </span>
      </div>
      <div className="flex flex-col w-full lg:w-1/2 h-full bg-white justify-center items-center lg:px-12 xl:px-28 py-8">
        <h1 className="text-3xl font-bold">Login</h1>
        <span className="text-lg text-gray-800">
          Please login to your account to continue
        </span>
        <div className="my-4"></div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-4 w-2/3"
          >
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="johndoe@example.com"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/*  */}
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input
                        type={showPassword ? "text" : "password"}
                        {...field}
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2"
                      >
                        {showPassword ? (
                          <EyeNoneIcon className="w-5 h-5 text-gray-500" />
                        ) : (
                          <EyeOpenIcon className="w-5 h-5 text-gray-500" />
                        )}
                      </button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/*  */}
            <div className="my-4"></div>
            {/*  */}
            <div className="flex flex-row justify-center items-center">
              <Link href="/forgot-password" className="text-sm underline">
                Forgot your password?
              </Link>
            </div>
            <div className="my-4"></div>
            {/*  */}
            <Button
              type={loading ? "button" : "submit"}
              onClick={loading ? undefined : form.handleSubmit(onSubmit)}
              className="w-full"
              variant="default"
            >
              {loading ? <Loader /> : "Login"}
            </Button>
          </form>
        </Form>
        <div className="my-4"></div>
        <div className="flex flex-row justify-center items-center">
          <span className="text-sm">Don&apos;t have an account?</span>
          <Link
            href="/register"
            className="text-sm underline ml-1 hover:text-blue-600"
          >
            Register
          </Link>
        </div>
      </div>
    </div>
  );
}

const Loader = () => <div className="loader">Loading...</div>;
