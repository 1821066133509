import { parse, serialize } from "cookie";
import { NextRequest } from "next/server";

export function getCookie(name: string): string | undefined {
  if (typeof document === "undefined") return undefined;
  const cookies = parse(document.cookie);
  return cookies[name];
}

export function setCookie(
  name: string,
  value: string,
  options: any = {}
): void {
  if (typeof document === "undefined") return;
  document.cookie = serialize(name, value, options);
}

export function getServerSideCookie(
  req: NextRequest,
  name: string
): string | undefined {
  const cookie = req.cookies.get(name);
  return cookie ? cookie.value : undefined;
}
